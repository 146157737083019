*,
::after,
::before {
  box-sizing: border-box;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Nanum+Gothic:wght@400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
/*@import url("https://fonts.googleapis.com/css2?family=Cabin&family=Roboto+Condensed:wght@400;700&display=swap");*/

html {
  font-size: 100%;
} /*16px*/

:root {
  /* colors */
  --primary-50: #e0fcff;
  --primary-100: #bef8fd;
  --primary-200: #8fb5ff;
  --primary-300: #6994f1;
  --primary-400: #4173cd;
  --primary-500: #0055aa;
  --primary-600: #004d99;
  --primary-700: #003c78;
  --primary-800: #0a6c74;
  --primary-900: #044e54;

  /* grey */
  --grey-50: #f0f4f8;
  --grey-100: #d9e2ec;
  --grey-200: #bcccdc;
  --grey-300: #9fb3c8;
  --grey-400: #829ab1;
  --grey-500: #627d98;
  --grey-600: #486581;
  --grey-700: #334e68;
  --grey-800: #243b53;
  --grey-900: #102a43;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #a91b0d;
  --red-dark: #92150a;
  --green-light: #009150;
  --green-dark: #0f5132;
  --green-dark: #0f5132;
  --brand-light-blue: #5db2d5;
  --black-background: #1a1919;
  --brand-blue: #212427;

  /* fonts  */
  --headingFont: "Montserrat", Sans-Serif;
  --bodyFont: "Montserrat", Sans-Serif;
  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 500px;
  --fluid-width: 90vw;
  --breakpoint-lg: 992px;
  --nav-height: 5rem;
  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body {
  background: var(--backgroundColor);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
}

p {
  margin-bottom: 1.5rem;
  max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--small-text);
}

a {
  text-decoration: none;
  letter-spacing: var(--letterSpacing);
}
a,
button {
  line-height: 1.15;
}
button:disabled {
  cursor: not-allowed;
}
ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}
/* buttons */

.btn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-500);
  border: transparent;
  border-radius: /*var(--borderRadius)*/ 5px;
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}
.btn:disabled {
  background-color: var(--primary-400);
}

.btn:hover {
  background: var(--primary-600);
  box-shadow: var(--shadow-4);
}
.btn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}
.btn-hipster:hover {
  color: var(--primary-200);
  background: var(--primary-600);
}
.btn-block {
  width: 100%;
}
.btn-hero {
  font-size: 1.25rem;
  padding: 0.5rem 1.25rem;
}
.no-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.btn-danger {
  background: var(--red-light);
  color: var(--white);
}
.btn-danger:hover {
  background: var(--red-dark);
  color: var(--white);
}
.btn-green {
  background: var(--green-light);
  color: var(--white);
}
.btn-green:hover {
  background: var(--green-dark);
  color: var(--white);
}
.delete-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: var(--white);
  padding: 20px;
  border-radius: 8px;
  border: 1px solid var(--grey-200);
  box-shadow: var(--shadow-1);
  width: 340px;
  text-align: center;
  text-transform: none;
  h3 {
    text-transform: none;
  }
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

/* alerts */
.alert {
  position: fixed;
  top: 5rem;
  right: 1rem;
  z-index: 1000;
  padding: 0.5rem 1rem;
  border-radius: var(--borderRadius);

  text-align: center;
  letter-spacing: var(--letterSpacing);
  transition: all 0.3s ease;
  animation: slideInFromRight 0.5s ease forwards;
}

.alert-danger {
  color: var(--white);
  background: var(--red-light);
  box-shadow: var(--shadow-2);
}
.alert-success {
  color: var(--white);
  background: var(--green-light);
  box-shadow: var(--shadow-2);
}

/* form */
.form {
  width: 90vw;
  max-width: var(--fixed-width);
  background: var(--white);
  border-radius: /*var(--borderRadius)*/ 5%;
  box-shadow: var(--shadow-2);
  padding: 0.75rem 1.5rem 1rem 1.5rem;
  margin: 1rem auto;
  transition: var(--transition);
}
.form:hover {
  box-shadow: var(--shadow-4);
}
.form-label {
  display: block;
  font-size: var(--smallText);
  margin-bottom: 0.1rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea,
.form-select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-200);
}
.form-input,
.form-select,
.btn-block {
  height: 35px;
}
.form-row {
  margin-bottom: 0.75rem;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.loading {
  position: fixed;
  top: 5rem;
  right: 1rem;
  z-index: 1000;
  width: 2.5rem;
  height: 2.5rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 2s linear infinite;
}
.loading-center {
  margin: 0 1rem;

  width: 2rem;
  height: 2rem;
}
.invoice-loading {
  position: flex;

  width: 1.5rem;
  height: 1.5rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 2s linear infinite;
}

/* title */
.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}

.container {
  width: var(--fluid-width);
  max-width: var(--max-width);
  margin: 0 auto;
}
.full-page {
  min-height: 100vh;
}

.coffee-info {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1rem;
  letter-spacing: var(--letterSpacing);
}
.coffee-info span {
  display: block;
}
.coffee-info a {
  color: var(--primary-500);
}

.back-to-menus {
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

.back-to-menus:hover {
  background-color: #f0f0f0;
}

.timeframe-selector {
  min-width: 8rem;
  height: 2.5rem;
  border-radius: 8px;
  box-shadow: var(--shadow-2);
  background: var(--white);
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 768px) {
    min-width: 10rem;
  }
}
.timeframe-selector:hover {
  box-shadow: var(--shadow-3);
}
.timeframe-selector {
  border: 1px solid var(--grey-200);
  position: relative;
}

.timeframe-selector select {
  width: 100%;
  padding: 0.5rem 0.75rem 0.5rem 0rem;

  background-color: transparent;
  color: var(--text-color);
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  max-width: 9rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.timeframe-selector select:focus {
  outline: none;
}

.timeframe-selector::after {
  content: "";

  position: absolute;
  right: 5px;
  top: 55%;
  border: 5px solid transparent;
  border-top-color: var(--text-color);
  transform: translateY(-50%);
  pointer-events: none;
}

.day-selector-container {
  display: flex;
  border: 1px solid var(--grey-100);
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  height: 2.25rem;
  /* margin-left: 0.5rem;
  margin-top: auto; */

  align-self: flex-end;
}

.day-selector-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  background-color: #f0f0f0;
  color: #000;
  font-size: 1rem;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.day-selector-button.selected {
  background-color: var(--primary-500);
  color: #fff;
}

.day-selector-button:not(:last-child) {
  margin-right: -1px;
}

.day-selector {
  display: flex;
  flex-direction: column;
}

.day-selector p {
  margin: 0;
  margin-bottom: 0.1rem;
}

.day-selector div {
  display: flex;
  height: 2.25rem;
}

.day-button {
  width: 40px; /* Example width, adjust as needed */
  height: 40px; /* Adjust height as needed */
  border: 1px solid black; /* Apply border to all sides initially */
  cursor: pointer;
  background-color: var(--grey-50); /* Default background color */
  color: #000; /* Text color */
  transition: background-color 0.3s;
}

/* Remove left border for all buttons except the first one */
.day-button:not(:first-child) {
  border-left: none;
}

.day-button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.day-button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.day-button.selected {
  background-color: var(--primary-500); /* Selected state color */
  color: #fff; /* Selected state text color */
}
@media screen and (min-width: 992px) {
  .coffee-info {
    text-align: left;
  }
  .coffee-info span {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFadeIn {
  from {
    transform: translateY(30px); /* Start from below */
    opacity: 0;
  }
  to {
    transform: translateY(0); /* End at the component's final position */
    opacity: 1;
  }
}

@keyframes slideFadeOut {
  from {
    transform: translateY(0); /* Start at the component's final position */
    opacity: 1;
  }
  to {
    transform: translateY(-30px); /* Slide up and out */
    opacity: 0;
  }
}

.slide-fade-enter {
  opacity: 0;
  transform: translateY(30px); /* Start from below */
  animation: slideFadeIn 0.5s ease forwards;
}

.slide-fade-exit {
  opacity: 1;
  transform: translateY(0); /* Start at the component's final position */
  animation: slideFadeOut 0.5s ease forwards;
}

.info-icon {
  margin-left: 0.25rem;
}

.calc-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding-top: 0.25rem;
  margin-left: -0.5rem;

  width: 2rem;
  height: 2rem;
}
.calc-button:hover {
  background: var(--grey-100);
  border-radius: 10px;
  color: inherit;
  border: none;

  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.connection-icon {
  position: fixed;
  top: 5rem;
  right: 1rem;
  z-index: 1000;
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--grey-200);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.changes-icon {
  position: fixed;
  top: 8rem;
  right: 1rem;
  z-index: 1000;
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--grey-200);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.beta-tag {
  background-color: #ffcc00;
  color: #000;
  padding: 0.25rem 0.25rem;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: bold;
  height: 1.75rem;
}
